import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import {StaticQuery, graphql} from 'gatsby';
import Img from 'gatsby-image/withIEPolyfill';


const ContactThanks = () => (
  <Layout>
    <SEO title="Sign Up" />
    <div className="relative container lg:max-w-4xl text-gray-700 bg-purple-300
                    pb-10 lg:pb-0">
      <Image/>
      <div className="lg:absolute inset-y-0 left-0 p-5 lg:m-10 lg:w-2/3">
        <h1 className="font-semibold text-2xl text-center my-2 font-serif">
              Thank You for Your Interest!
        </h1>
        <p className="font-medium text-xl">The Daily Coach team is doing closed
          user-testing on the new Daily Coach App.  We will be in touch with
          updates soon.
        </p>
      </div>
    </div>
  </Layout>
);

export default ContactThanks;

const Image = () => (
  <StaticQuery
    query={graphql`
      query {
        placeholderImage: file(relativePath: { eq: "manonrocks_c.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1200 quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={(data) =>
      <Img
        fluid={data.placeholderImage.childImageSharp.fluid}
        objectFit="cover"
      />}
  />
);
